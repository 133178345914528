var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { padding: "20px" } },
    [
      _c(
        "el-row",
        { staticStyle: { padding: "0px 0px 20px 0px" } },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handlerAdd } },
            [_vm._v("添加收款方式")]
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "50%" },
              attrs: { stripe: "", data: _vm.tableData },
            },
            [
              _c("el-table-column", {
                attrs: { align: "center", type: "index" },
              }),
              _c("el-table-column", {
                attrs: { label: "排序", align: "center", prop: "sort" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input", {
                          on: {
                            change: function ($event) {
                              return _vm.handlerdlur(scope.row)
                            },
                          },
                          model: {
                            value: scope.row.sort,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "sort", $$v)
                            },
                            expression: "scope.row.sort",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "收款方式", align: "center", prop: "name" },
              }),
              _c("el-table-column", {
                attrs: { label: "备注", align: "center", prop: "remark" },
              }),
              _c("el-table-column", {
                attrs: { label: "状态", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-switch", {
                          attrs: {
                            "active-color": "#13ce66",
                            "inactive-color": "#ccc",
                            "active-value": "1",
                            "inactive-value": "2",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.handlerSwitch(scope.row)
                            },
                          },
                          model: {
                            value: scope.row.status,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "status", $$v)
                            },
                            expression: "scope.row.status",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handlerentd(scope.row)
                              },
                            },
                          },
                          [_vm._v(" 修改 ")]
                        ),
                        _c(
                          "el-popconfirm",
                          {
                            attrs: {
                              icon: "el-icon-info",
                              "icon-color": "red",
                              title: "确定删除吗？",
                            },
                            on: {
                              confirm: function ($event) {
                                return _vm.deleterouter(scope.row)
                              },
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: { slot: "reference", type: "text" },
                                slot: "reference",
                              },
                              [_vm._v(" 删除 ")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("add-payment", { ref: "payment", on: { getlist: _vm.getlist } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }