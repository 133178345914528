<!--
 * @Author: your name
 * @Date: 2021-01-26 14:44:26
 * @LastEditTime: 2021-01-26 17:09:18
 * @LastEditors: Please set LastEditors
 * @Description: 收款方式设置
 * @FilePath: \sd-vue-admin\src\views\setting\otherManagement\receivePayment\index.vue
-->
<!-- NAME[epic=设置] 收款方式设置 -->
<template>
  <div style="padding: 20px">
    <el-row style="padding: 0px 0px 20px 0px">
      <el-button type="primary" @click="handlerAdd">添加收款方式</el-button>
    </el-row>
    <el-row>
      <el-table stripe :data="tableData" style="width: 50%">
        <el-table-column align="center" type="index"></el-table-column>
        <el-table-column label="排序" align="center" prop="sort">
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.sort"
              @change="handlerdlur(scope.row)"
            />
          </template>
        </el-table-column>
        <el-table-column
          label="收款方式"
          align="center"
          prop="name"
        ></el-table-column>
        <el-table-column
          label="备注"
          align="center"
          prop="remark"
        ></el-table-column>
        <el-table-column label="状态" align="center">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.status"
              active-color="#13ce66"
              inactive-color="#ccc"
              :active-value="'1'"
              :inactive-value="'2'"
              @change="handlerSwitch(scope.row)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="handlerentd(scope.row)">
              修改
            </el-button>
            <el-popconfirm
              icon="el-icon-info"
              icon-color="red"
              title="确定删除吗？"
              @confirm="deleterouter(scope.row)"
            >
              <el-button slot="reference" type="text" style="margin-left: 10px">
                删除
              </el-button>
            </el-popconfirm>
            <!-- <el-button type="text">删除</el-button> -->
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <add-payment ref="payment" @getlist="getlist"></add-payment>
  </div>
</template>

<script>
  import AddPayment from './components/AddPayment.vue'
  import { postAction } from '@/api/Employee'
  export default {
    components: {
      AddPayment,
    },
    data() {
      return {
        tableData: [],
        url: {
          list: '/setAdmin/pay/list',
          switch: '/setAdmin/pay/change-status',
          stor: '/setAdmin/pay/sort',
          delete: '/setAdmin/pay/delete',
        },
      }
    },
    created() {
      this.handlerlist()
    },
    methods: {
      deleterouter(row) {
        postAction(this.url.delete, { id: row.id })
          .then((res) => {
            console.log(res)
            this.$message({
              message: res.msg,
              type: 'success',
            })
            this.handlerlist()
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handlerentd(row) {
        this.$refs.payment.handlerData(row)
        this.$refs.payment.showpay = true
      },
      getlist() {
        this.handlerlist()
      },
      handlerAdd() {
        console.log(this.$refs.payment)
        this.$refs.payment.showpay = true
      },
      handlerdlur(row) {
        postAction(this.url.stor, { id: row.id, sort: row.sort })
          .then((res) => {
            console.log(res)
            this.handlerlist()
          })
          .catch((err) => {
            console.log((err) => {
              console.log(err)
            })
          })
      },
      handlerlist() {
        postAction(this.url.list, {})
          .then((res) => {
            console.log(res)
            this.tableData = res.data
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handlerSwitch(row) {
        console.log(row)
        postAction(this.url.switch, { id: row.id })
          .then((res) => {
            console.log(res)
            this.$message({
              message: res.msg,
              type: 'success',
            })
            this.handlerlist()
          })
          .catch((err) => {
            console.log(err)
          })
      },
    },
  }
</script>

<style></style>
