<!--
 * @Author: your name
 * @Date: 2021-01-26 16:18:44
 * @LastEditTime: 2021-02-22 16:57:43
 * @LastEditors: Please set LastEditors
 * @Description: 添加支付方式
 * @FilePath: \sd-vue-admin\src\views\setting\otherManagement\receivePayment\components\AddPayment.vue
-->
<template>
  <div>
    <el-dialog
      :modal="false"
      :visible.sync="showpay"
      :title="title"
      center
      width="600px"
    >
      <div>
        <el-form ref="form" :model="form" label-width="180px" :rules="rules">
          <el-form-item label="名称：" prop="name">
            <el-input
              v-model="form.name"
              placeholder="请输入支付名称"
              style="width: 220px"
            />
          </el-form-item>
          <el-form-item label="备注：" prop="remark">
            <el-input
              v-model="form.remark"
              type="textarea"
              placeholder="请输入备注内容"
              maxlength="30"
              style="width: 220px"
            />
          </el-form-item>
          <el-form-item label="状态：" prop="status">
            <el-radio-group v-model="form.status">
              <el-radio label="1">正常</el-radio>
              <el-radio label="2">停用</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handlersave">保 存</el-button>
        <el-button @click="showpay = !showpay">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { postAction } from '@/api/Employee'
  export default {
    data() {
      return {
        showpay: false,
        title: '新建支付方式',
        form: {
          // id:修改时传id，添加时传0
          name: '', //,名称
          remark: '', //备注
          status: '1', //状态1开启，2关闭
        },
        rules: {
          name: [
            { required: true, message: '请输入支付方式名称', trigger: 'blur' },
          ],
          remark: [{ required: true, message: '请输入备注', trigger: 'blur' }],
          status: [
            {
              required: true,
              message: '请选择状态',
              trigger: 'change',
            },
          ],
        },
        url: {
          save: '/setAdmin/pay/add',
        },
      }
    },
    watch: {
      showpay(val) {
        if (val == false) {
          this.title = '新建支付方式'
          this.$refs['form'].resetFields()
          this.form.name = ''
          this.form.remark = ''
          this.form.status = '1'
          try {
            delete this.form.id
          } catch (error) {}
        }
      },
    },
    methods: {
      handlerData(row) {
        this.title = '修改支付方式'
        this.$set(this.form, 'id', row.id)
        this.form.name = row.name
        this.form.remark = row.remark
        this.form.status = row.status
      },
      handlersave() {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            postAction(this.url.save, this.form)
              .then((res) => {
                console.log(res)
                this.$message({
                  message: res.msg,
                  type: 'success',
                })
                this.$emit('getlist')
                this.showpay = false
              })
              .catch((err) => {
                console.log(err)
              })
          } else {
            console.log('error submit!!')
            return false
          }
        })

        console.log(this.form)
      },
    },
  }
</script>

<style></style>
